import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LayoutWrapper from 'components/LayoutWrapper';
import TermContent from 'components/Template';
import { CMSWrapper } from 'configs/globalStyle';

const PrivacyPolicy = (): FC => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPrivacyPolicy  {
        edges {
          node {
            data {
              title {
                raw
              }
              content {
                raw
              }
            }
          }
        }
      }
    }
  `);

  const document = data?.allPrismicPrivacyPolicy?.edges[0]?.node?.data;
  return (
    <LayoutWrapper>
      <CMSWrapper>
        <TermContent data={document} />
      </CMSWrapper>
    </LayoutWrapper>
  );
};

export default PrivacyPolicy;
